<script setup lang="ts">
import { useLocalePath } from '#i18n'

const localePath = useLocalePath()

const isToggleActive = ref(false)
const onToggle = () => {
  isToggleActive.value = !isToggleActive.value
}

const menuItems = [
  {
    id: 'game-plan',
    icon: '/img/icons/game-plan-icon@3.webp',
    text: 'Herní plán',
    link: { name: 'levels', query: { t: 'exercise' } },
  },
  {
    id: 'game-plan',
    icon: '/img/icons/training-icon@3.webp',
    text: 'Tréninková místnost',
    link: { name: 'levels', query: { t: 'trainingRoom' } },
  },
  {
    id: 'game-plan',
    icon: '/img/icons/stretching-icon@3.webp',
    text: 'Protahování',
    link: { name: 'levels', query: { t: 'stretching' } },
  },
]
const router = useRouter()
const authenticationStore = useAuthenticationStore()
const { fullName, isAuthenticated } = authenticationStore
const { user } = storeToRefs(authenticationStore)

const onLogout = () => {
  authenticationStore.logout()
  router.push(localePath({ name: 'login' }))
}
</script>

<template>
  <div class="AppMenu h-full p-4">
    <div class="AppMenu__mobile md:hidden">
      <div class="flex justify-between items-center">
        <nuxt-link :to="localePath({ name: 'levels' })">
          <div class="AppMenu__logo">
            <img
              src="/img/logo-black.webp"
              class="w-32"
              alt="Sixpack Logo"
            >
          </div>
        </nuxt-link>
        <div
          class="tham tham-e-squeeze tham-w-6"
          :class="{ 'tham-active': isToggleActive }"
          @click="onToggle"
        >
          <div class="tham-box">
            <div class="tham-inner" />
          </div>
        </div>
      </div>
      <div
        v-if="isToggleActive"
        class="w-full pt-4"
      >
        <div class="AppMenu__items">
          <nuxt-link
            v-for="item in menuItems"
            :key="item.id"
            :to="localePath(item.link)"
            class="AppMenu__item flex items-center mb-2"
            @click="onToggle"
          >
            <img
              :src="item.icon"
              alt="Menu icon"
              class="w-8 mr-2"
            >
            <div>{{ $t(item.text) }}</div>
          </nuxt-link>
          <ClientOnly>
            <template v-if="isAuthenticated">
              <hr class="my-6">
              <a
                :href="user?.healthPlanPath"
                target="_blank"
                class="AppMenu__item flex items-center mb-2"
              >
                Zdravotní plán
              </a>
            </template>
          </ClientOnly>
        </div>
      </div>
    </div>
    <div class="AppMenu__desktop h-full hidden md:flex flex-col items-stretch justify-between">
      <nuxt-link :to="localePath({ name: 'levels' })">
        <div class="AppMenu__logo mb-8">
          <img
            src="/img/logo-black.webp"
            alt="Sixpack Logo"
          >
        </div>
      </nuxt-link>
      <div class="AppMenu__items">
        <nuxt-link
          v-for="item in menuItems"
          :key="item.id"
          :to="localePath(item.link)"
          class="AppMenu__item flex items-center mb-2"
        >
          <img
            :src="item.icon"
            alt="Menu icon"
            class="w-8 mr-2"
          >
          <div>{{ $t(item.text) }}</div>
        </nuxt-link>
        <ClientOnly>
          <template v-if="isAuthenticated">
            <hr class="my-6">
            <a
              :href="user?.healthPlanPath"
              target="_blank"
              class="AppMenu__item flex items-center mb-2"
            >
              Zdravotní plán
            </a>
          </template>
        </ClientOnly>
      </div>
      <div class="grow" />
      <div class="AppMenu__profile flex justify-between items-center">
        <ClientOnly>
          <div class="text-black font-bold">
            {{ fullName }}
          </div>
        </ClientOnly>
        <div
          class="btn btn--black btn--sm"
          @click="onLogout"
        >
          Odhlásit
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
.AppMenu__item {
  @apply text-black font-bold underline;

  &.router-link-active {
    @apply no-underline;
  }
}
</style>
