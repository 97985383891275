<template>
  <div class="flex w-full h-full min-h-screen flex-col md:flex-row">
    <div class="w-full md:w-60 flex-shrink-0 bg-gradient-to-br from-yellow to-yellow_light">
      <LayoutAppMenu />
    </div>
    <div class="flex-grow bg-gradient-to-br from-black to-gray">
      <slot />
    </div>
    <div id="teleports" />
  </div>
</template>

<script setup lang="ts">
import { useLocalePath } from '#imports'

onMounted(() => {
  const authStore = useAuthenticationStore()
  if (!authStore.isAuthenticated) {
    const localePath = useLocalePath()
    const router = useRouter()
    const path = localePath({ name: 'login' })
    console.log(path)
    router.push(path)
  }
})
</script>
